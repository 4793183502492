<template>
  <el-container class="layout">
    <el-aside width="200px">
      <p class="logo"><img src="@/assets/images/logo.svg" alt="" /></p>
      <el-menu
        :default-active="$route.path"
        router
        class="menu"
        :unique-opened="true"
        @open="handleOpen"
        @close="handleClose"
        background-color="#233A4D"
        text-color="#CACBD2"
        active-text-color="#409EFF"
      >
        <Menuitem :menu="$router.options.routes[1].children" />
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="header">
        <!-- <span class="blue pointer">master</span> -->
        <!-- <span>赵赵</span> -->
        <span class="blue pointer" @click="signOut">退出</span>
      </el-header>
      <el-main class="main">
        <el-breadcrumb class="breadcrumb">
          <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
            <!-- <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path" :to="{ path: item.path }"> -->
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Menuitem from './menuitem'
import Cookie from '@/utils/cookie'

export default {
  name: 'Layout',
  components: { Menuitem },
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    //  面包屑
    getBreadcrumb() {
      this.levelList = this.$route.matched.filter((item) => item.meta && item.meta.title)
      // console.log(666, this.levelList)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    // 退出登录
    signOut() {
      Cookie.removeToken()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  // 侧边栏
  .el-aside {
    background: #233a4d;
    .logo {
      width: 100%;
      height: 76px;
      line-height: 76px;
      text-align: center;
      background: #233a4d;
      & > img {
        width: 140px;
        vertical-align: middle;
      }
    }
    .el-menu {
      border-right: none;
    }
  }
  // 内容部分-头
  .header {
    line-height: 60px;
    text-align: right;
    span:nth-of-type(2) {
      margin: 0 16px;
    }
  }
  // 内容部分-主体
  .main {
    background: #f0f2f5;
    .breadcrumb {
      margin-bottom: 20px;
    }
    & > div:nth-of-type(2) {
      background: #fff;
      border-radius: 5px;
      padding: 20px;
    }
  }
}
</style>

