<template>
  <div class="menuitem">
    <label v-for="(item, index) in menu" :key="index">
      <!-- 有的路由是不在菜单栏展示的 -->
      <template v-if="!item.meta.hidden">
        <el-submenu :index="`/${item.path}`" v-if="item.children && ss(item.children)">
          <template slot="title">
            <i :class="item.meta.icon" />
            <span>{{ item.meta.title }}</span>
          </template>
          <label>
            <Menuitem :menu="item.children" />
          </label>
        </el-submenu>
        <el-menu-item v-else :index="`/${item.path}`" @click='dd'>
          <i :class="item.meta.icon" />
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </template>
    </label>
  </div>

  <!-- <div v-if="item.children">
    <template v-if="item.children.length == 0">
      <el-menu-item :index="item.path">
        <i class="el-icon-document"></i>
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
    <el-submenu v-else :index="item.path">
      <template slot="title">
        <i class="el-icon-location"></i>
        <span slot="title" v-show="!collapse">{{ item.title }}</span>
      </template>

      <template v-for="child in item.children">
        <Menuitem v-if="child.children && child.children.length > 0" :item="child" :key="child.path" />
        <el-menu-item v-else :key="child.path" :index="child.path">
          <i class="el-icon-location"></i>
          {{ child.title }}
        </el-menu-item>
      </template>
    </el-submenu>
  </div> -->
</template> 
  


<script>
export default {
  name: 'Menuitem',
  props: {
    menu: {
      type: Array,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {
    // console.log(888, this.menu)
  },
  methods: {
    ss(arr) {
      const re = arr.find((val) => !val.meta.hidden)
      return re
    },
    dd(){
      
    }
  }
}
</script>

<style lang="scss" scoped>
.menuitem {
  i {
    // color: #fff;
  }
}
</style>

